<nav class="d-none col-md-3 col-lg-2 d-md-block sidebar">
  <div class="position-sticky pt-3">
    <div class="main-menu">
      <ul class="nav flex-column">
        @for (item of menu; track $index) {
        <!-- menu item -->
        @if (item.show) {
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="item.url">
            <i [class]="item.icon"></i>
            {{ item.title }}
          </a>
        </li>
        } }
      </ul>
    </div>

    <!-- Charts -->
    <div class="charts-menu">
      <p class="px-3 my-0">Gráficos</p>
      <ul class="nav flex-column">
        @for (item of graficos; track $index) {
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="item.url">
            <i [class]="item.icon"></i>
            {{ item.title }}
          </a>
        </li>
        }
      </ul>
    </div>

    <!-- Units -->
    @if (isGestor) {
    <div class="unidades-menu">
      <p class="px-3 my-0">Unidades</p>
      <ul class="nav flex-column">
        @for (item of unidades; track $index) {
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="item.url">
            <i [class]="item.icon"></i>
            {{ item.title }}
          </a>
        </li>
        }
      </ul>
    </div>
    }

    <!-- Legacy -->
    @if (!isCustomer) {
    <div class="legacy-menu">
      <p class="px-3 my-0">Legado</p>
      <ul class="nav flex-column">
        @for (item of relatorios; track $index) {
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="item.url">
            <i class="bi bi-file-ruled"></i>
            {{ item.title }}
          </a>
        </li>
        }
      </ul>
    </div>
    }
  </div>
</nav>
