import { Component, inject } from '@angular/core';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from '../toast.service';

@Component({
  selector: 'app-toaster',
  standalone: true,
  imports: [NgbToastModule],
  templateUrl: './toaster.component.html',
  styleUrl: './toaster.component.scss',
})
export class ToasterComponent {
  toastService = inject(ToastService);
}
