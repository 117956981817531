<header class="navbar navbar-dark sticky-top flex-md-nowrap shadow">
  <a class="navbar-brand col-md-3 col-lg-2" href="/">
    <img src="/assets/satis_marca_white.png" class="marca" />
    <div></div>
    <img src="/assets/sistema_gestor_white.png" class="gestor" />
  </a>

  @if (authService.isAuthenticated()) {
  <div class="select-unit nav-item">
    <ng-multiselect-dropdown
      [settings]="unidadesSelectSettings"
      placeholder="Selecione a unidade"
      [data]="unidades"
      [(ngModel)]="unidadeSelecionada"
      (onSelect)="atualizaUnidade($event)"
    />
  </div>
  }

  <div class="navbar-nav">
    @if (authService.isAuthenticated()) {
    <div class="d-flex align-items-center gap-2 user nav-item text-nowrap">
      <i class="bi bi-person"></i>
      <span class="me-4">{{ user }}</span>

      <a (click)="onLogout()" role="button">
        <i class="bi bi-box-arrow-right"></i>
      </a>
    </div>
    }
  </div>
</header>
