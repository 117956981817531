<app-header />
<div class="container-fluid">
  <div class="row">
    @if (auth.isAuthenticated()) {
    <app-menu />
    }
    <main
      class="px-md-4 mt-3 mb-4"
      [ngClass]="{ 'col-md-9 ms-sm-auto col-lg-10': auth.isAuthenticated() }"
    >
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
@if (loading$ | async) {
<app-loading />
}

<app-toaster />
