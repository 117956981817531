import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from './auth.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(AuthService);

  if (auth.isAuthenticated()) {
    const authRequest = req.clone({
      setHeaders: {
        Authorization: `token ${auth.token}`,
      },
    });

    return next(authRequest).pipe(
      catchError((err) => {
        if (err.status === 401) {
          auth.logout();
        }
        console.error(err);

        const error = err.error.message || err.statusText;
        return throwError(() => new Error(error));
      })
    );
  }
  return next(req);
};
