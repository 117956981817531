import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { HeaderComponent } from './core/layout/header/header.component';
import { MenuComponent } from './core/layout/menu/menu.component';
import { UsuarioService } from './core/services/usuario.service';
import { LoadingComponent } from './shared/loading/loading.component';
import { LoadingService } from './shared/loading/loading.service';
import { ToasterComponent } from './shared/toast/toaster/toaster.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    MenuComponent,
    LoadingComponent,
    ToasterComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  router = inject(Router);
  auth = inject(AuthService);
  usuarioService = inject(UsuarioService);
  changeDetector = inject(ChangeDetectorRef);

  loadingService = inject(LoadingService);
  loading$ = this.loadingService.loading$;

  isLogged: boolean = false;
  username: string | undefined;
  isSuper: boolean = false;

  constructor() {}

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit() {
    this.auth.authenticationState.subscribe((state) => {
      if (state) {
        this.isLogged = true;
        this.username = this.auth.userData.Nome;
        this.isSuper = this.auth.isSuper();
        this.router.navigate(['home']);
      } else {
        this.isLogged = false;
        this.router.navigate(['login']);
      }
    });
  }
}
