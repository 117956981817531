import { Routes } from '@angular/router';
import { authGuard } from './core/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
    canActivate: [authGuard],
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/login/login.component').then((m) => m.LoginComponent),
  },
  {
    path: 'usuarios',
    loadComponent: () =>
      import('./pages/usuario-lista/usuario-lista.component').then(
        (m) => m.UsuarioListaComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'gestores',
    loadComponent: () =>
      import('./pages/gestor-lista/gestor-lista.component').then(
        (m) => m.GestorListaComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'gestores/:id/edit',
    loadComponent: () =>
      import('./pages/gestor-edicao/gestor-edicao.component').then(
        (m) => m.GestorEdicaoComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'gestores/new',
    loadComponent: () =>
      import('./pages/gestor-edicao/gestor-edicao.component').then(
        (m) => m.GestorEdicaoComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'faixas',
    loadComponent: () =>
      import('./pages/faixa-lista/faixa-lista.component').then(
        (m) => m.FaixaListaComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'faixas/:id/edit',
    loadComponent: () =>
      import('./pages/faixa-editar/faixa-editar.component').then(
        (m) => m.FaixaEditarComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'faixas/new',
    loadComponent: () =>
      import('./pages/faixa-editar/faixa-editar.component').then(
        (m) => m.FaixaEditarComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'unidade',
    loadComponent: () =>
      import('./pages/unidade-editar/unidade-editar.component').then(
        (m) => m.UnidadeEditarComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'mensagens',
    loadComponent: () =>
      import('./pages/mensagem-lista/mensagem-lista.component').then(
        (m) => m.MensagemListaComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'mensagens/:id',
    loadComponent: () =>
      import('./pages/mensagem-detalhe/mensagem-detalhe.component').then(
        (m) => m.MensagemDetalheComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'notificacao',
    loadComponent: () =>
      import('./pages/notificacao-enviar/notificacao-enviar.component').then(
        (m) => m.NotificacaoEnviarComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'notificacoes',
    loadComponent: () =>
      import('./pages/notificacao-lista/notificacao-lista.component').then(
        (m) => m.NotificacaoListaComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'grafico-satisfacao',
    loadComponent: () =>
      import('./pages/grafico-satisfacao/grafico-satisfacao.component').then(
        (m) => m.GraficoSatisfacaoComponent
      ),
    canActivate: [authGuard],
  },
  {
    path: 'grafico-insatisfacao',
    loadComponent: () =>
      import(
        './pages/grafico-insatisfacao/grafico-insatisfacao.component'
      ).then((m) => m.GraficoInsatisfacaoComponent),
    canActivate: [authGuard],
  },
  {
    path: 'report/:cod_report',
    loadComponent: () =>
      import('./pages/report/report.component').then((m) => m.ReportComponent),
    canActivate: [authGuard],
  },
  {
    path: 'gerar-csv-mensal',
    loadComponent: () =>
      import('./pages/gerar-csv-mensal/gerar-csv-mensal.component').then(
        (m) => m.GerarCsvMensalComponent
      ),
    canActivate: [authGuard],
  },
];
