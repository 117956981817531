import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss',
})
export class MenuComponent {
  authService = inject(AuthService);
  usuarioService = inject(UsuarioService);

  isGestor = false;
  isCustomer = false;
  menu: any = [];

  ngOnInit() {
    this.authService.authenticationState.subscribe((state) => {
      if (state) {
        this.isGestor = this.authService.isSuperUser;
        this.isCustomer = this.authService.isSuperCustomer;
        this.setupMenu();
      }
    });
  }

  setupMenu() {
    this.menu = [
      {
        title: 'Home',
        icon: 'bi bi-house-door-fill',
        url: '/home',
        show: true,
      },
      {
        title: 'Usuários',
        icon: 'bi bi-people-fill',
        url: '/usuarios',
        show: !this.isCustomer,
      },
      {
        title: 'Gestores',
        icon: 'bi bi-person-fill-gear',
        url: '/gestores',
        show: !this.isCustomer,
      },
      {
        title: 'Horários',
        icon: 'bi bi-clock-fill',
        url: '/faixas',
        show: !this.isCustomer,
      },
      {
        title: 'Configurações',
        icon: 'bi bi-gear-fill',
        url: '/unidade',
        show: !this.isCustomer,
      },
      {
        title: 'Mensagens',
        icon: 'bi bi-envelope-fill',
        url: '/mensagens',
        show: !this.isCustomer,
      },
      {
        title: 'Enviar notificação',
        icon: 'bi bi-bell-fill',
        url: '/notificacao',
        show: !this.isCustomer,
      },
    ];
  }

  graficos = [
    {
      title: 'Satisfação',
      icon: 'bi bi-graph-up-arrow',
      url: '/grafico-satisfacao',
    },
    {
      title: 'Insatisfação',
      icon: 'bi bi-graph-down-arrow',
      url: '/grafico-insatisfacao',
    },
  ];

  unidades = [
    {
      title: 'Gerar CSV Mensal',
      icon: 'bi bi-filetype-csv',
      url: '/gerar-csv-mensal',
    },
  ];

  relatorios = [
    {
      title: 'Reservas de refeições',
      url: '/report/1',
    },
    {
      title: 'Satisfação analítico',
      url: '/report/2',
    },
    {
      title: 'Satisfação consolidado',
      url: '/report/3',
    },
    {
      title: 'Insatisfação c/ Cardápios',
      url: '/report/4',
    },
    {
      title: 'Satisfação mensal',
      url: '/report/5',
    },
    {
      title: 'Satisfação diário',
      url: '/report/6',
    },
    {
      title: 'Satisfação c/ nros absolutos',
      url: '/report/7',
    },
    {
      title: 'Gráfico de especialidades',
      url: '/report/8',
    },
    {
      title: 'Gráfico de satisfação',
      url: '/report/9',
    },
    {
      title: 'Gráfico motivos de insatisfação',
      url: '/report/10',
    },
  ];

  constructor() {}
}
