import { Component, ViewEncapsulation, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  IDropdownSettings,
  NgMultiSelectDropDownModule,
} from 'ng-multiselect-dropdown';
import { AuthService } from '../../auth/auth.service';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [FormsModule, NgMultiSelectDropDownModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent {
  authService = inject(AuthService);
  usuarioService = inject(UsuarioService);

  unidadeSelecionada: any = null;
  unidades: any[] = [];

  user: string | null = null;

  unidadesSelectSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'nome',
    itemsShowLimit: 1,
    allowSearchFilter: true,
    searchPlaceholderText: 'Busca',
    noDataAvailablePlaceholderText: 'Não encontrado',
    noFilteredDataAvailablePlaceholderText: 'Não encontrado',
    closeDropDownOnSelection: true,
  };

  onLogout() {
    this.authService.logout();
  }

  ngOnInit() {
    this.authService.authenticationState.subscribe((state) => {
      if (state) {
        this.unidades = this.authService.unidades;
        this.unidadeSelecionada = [this.authService.unidadeSelecionada];
        this.user = this.authService.userData.fullname;

        console.log('unidade selecionada', this.unidadeSelecionada);
      }
    });
  }

  atualizaUnidade(event: any) {
    this.authService.unidadeSelecionada = this.unidadeSelecionada[0];
    this.usuarioService.changeUnitState.next(true);
  }
}
