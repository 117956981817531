<div class="toaster position-fixed top-0 end-0 p-3 z-3">
  @for (toast of toastService.toasts; track $index) {
  <ngb-toast
    [class]="toast.type"
    [autohide]="true"
    [delay]="5000"
    (hidden)="toastService.remove(toast)"
    class="mb-2 border border-0"
  >
    {{ toast.message }}
  </ngb-toast>
  }
</div>
